import { styled } from '@a2d24-ui/theme';

export const Label = styled('label', {
    position: 'absolute',
    padding: '0 3px 1px',
    borderRadius: '10px',
    top: '35%',
    left: '15px',
    zIndex: 0,
    color: '$placeholderText',
    transition: 'transform 150ms ease-out, font-size 150ms ease-out',
    variants: {
        error: {
            true: {
                color: '$warning !important',
            },
        },
    },
});

export const HelperText = styled('div', {
    fontSize: '$4',
    paddingTop: '$0',
    variants: {
        error: {
            true: {
                color: '$warning !important',
            },
        },
    },
});

export const LabelBackground = styled('div', {
    width: '100%',
    height: '1px',
    position: 'absolute',
    content: '',
    backgroundColor: '$backgroundCard',
    zIndex: -1,
    top: '8px',
    left: '0',
});

export const LabelText = styled('div', { zIndex: 0 });

export const topLabel = {
    top: '2px',
    left: '5px',
    color: '$primary',
    fontSize: '0.85rem',
};

export const topLabelNotFocussed = {
    color: '$placeholderText',
};

export const StaticInputStyles = {
    minHeight: '$inputFieldHeight',
    height: '$inputFieldHeight',
    border: '1px solid $inputBorder',
    borderRadius: '5px',
    margin: '10px 0',
    backgroundColor: '$backgroundCard',
    color: '$hiContrast',
    position: 'relative',
    padding: '0 10px',
    width: '100%',
    outline: '0',
    boxShadow: '0 1px 0 0 $inputBoxShadow',
    transition: 'box-shadow 150ms ease-out',
    '&::placeholder': {
        color: 'transparent',
    },
    '&:disabled': {
        opacity: '0.3',
        color: '$hiContrast',
    },
    '&&::-webkit-calendar-picker-indicator': {
        filter: 'var(--filters-datePickerCalendarIconFilter)',
    },
    '&:focus': {
        border: '1px solid $primary',
    },
    [`&:not(:placeholder-shown) ~ ${Label}`]: { ...topLabel, ...topLabelNotFocussed },
    [`&:focus ~ ${Label}`]: { ...topLabel },
    variants: {
        error: {
            true: {
                borderColor: '$warning !important',
            },
        },
    },
};

export const StyledInput = styled('input', { ...StaticInputStyles });

export const InputBox = styled('div', {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '55px 15px',
    height: 'fit-content',
});
