import React from 'react';
import { styled } from '@a2d24-ui/theme';

const AuthGraphicContainer = styled('div', {
    position: 'relative',
    opacity: 1,
    width: 350,
    height: 370,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userDrag: 'none',
    MozUserSelect: 'none',
    WebkitUserDrag: 'none',
    WebkitUserSelect: 'none',

    '&:before': {
        content: '',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: '$secondary4',
        borderRadius: '57% 43% 62% 38% / 68% 43% 57% 32%',
        zIndex: -2,
    },

    '&:after': {
        content: '',
        display: 'block',
        position: 'absolute',
        width: '90%',
        height: '90%',
        background: '$secondary3',
        borderRadius: '18% 82% 45% 40% / 57% 35% 35% 43%',
        zIndex: -1,
    },
});

export function AuthGraphic({ image }) {
    return (
        <AuthGraphicContainer>
            <img src={image} alt="Secure your account" />
        </AuthGraphicContainer>
    );
}
