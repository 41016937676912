import React, { useEffect, useState } from 'react';
import {
    InputBox,
    StyledInput,
    Label,
    LabelBackground,
    LabelText,
    HelperText,
} from './InputStyles';

export const TextInput = React.forwardRef((props, ref) => {
    const { id, onChange, placeholder, disabled, value, error, style = {} } = props;
    const {
        initialValue,
        onNext,
        input,
        meta,
        css,
        useInputEvent,
        onInputChange,
        helperText,
        ...others
    } = props;
    const showError = meta ? meta?.touched && meta?.invalid : error;
    const [text, setText] = useState(initialValue);

    useEffect(() => {
        !useInputEvent && onChange && onChange(text);
    }, [text]);

    useEffect(() => {
        setText(initialValue);
    }, [initialValue]);
    const inputId = input?.name || id;

    return (
        <InputBox css={{ ...css }}>
            <StyledInput
                ref={ref}
                id={input?.name || id}
                autoComplete={'off'}
                value={props.value || text || ''}
                type={'text'}
                style={{ ...style }}
                {...input}
                onChange={(e) => {
                    input?.onChange && input.onChange(e);
                    useInputEvent && onChange && onChange(e);
                    !useInputEvent && setText(e.target.value);
                    if (onInputChange) {
                        useInputEvent ? onInputChange(e) : onInputChange(e.target.value);
                    }
                }}
                error={meta ? showError : error}
                onKeyPress={(event) => {
                    if (event.key === 'Enter' && onNext) {
                        event.preventDefault();
                        onNext();
                    }
                }}
                {...others}
            />
            <Label htmlFor={inputId} error={showError}>
                <LabelBackground />
                <LabelText>{placeholder}</LabelText>
            </Label>
            <HelperText error={showError}>
                {showError ? (meta?.error ? meta?.error : error) : helperText}
            </HelperText>
        </InputBox>
    );
});
