import { styled } from '@a2d24-ui/theme';

const Fieldset = styled('fieldset', {
    all: 'unset',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,

    '&:focus-within': {
        'label, span:first-of-type': {
            fontWeight: 500,
        },
    },
    marginBottom: '$2',
});

export default Fieldset;
