import { styled } from '@a2d24-ui/theme';

const Button = styled('button', {
    all: 'unset',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    padding: '0 15px',
    fontSize: '1.25em',
    lineHeight: 1,
    fontWeight: 500,
    height: '2.5em',
    cursor: 'pointer',
    boxSizing: 'border-box',
    userSelect: 'none',

    variants: {
        variant: {
            primary: {
                backgroundColor: '$primary5',
                border: '2px solid $primary5',
                color: 'white',
                '&:hover:enabled, &:focus:enabled': {
                    backgroundColor: '$primary',
                    border: '2px solid $primary',
                },
                '&:focus': { boxShadow: `0 0 0 2px $colors$primary5` },
            },
            secondary: {
                backgroundColor: '$secondary',
                color: 'white',
                border: '2px solid $secondary',
                '&:hover:enabled': {
                    border: '2px solid $secondaryTextDark',
                    backgroundColor: '$secondaryTextDark',
                },
                '&:focus': { boxShadow: `0 0 0 2px $colors$secondaryTextDark` },
            },
            primaryLight: {
                backgroundColor: '$primary',
                color: 'white',
                border: '2px solid $primary',
                '&:hover:enabled, &:focus:enabled': {
                    border: '2px solid $primary5',
                    backgroundColor: '$primary5',
                },
                '&:focus': { boxShadow: `0 0 0 2px $colors$primary` },
            },
            outlined: {
                backgroundColor: 'white',
                border: '2px solid $secondary',
                color: '$secondary',
                '&:hover:enabled, &:focus:enabled': {
                    backgroundColor: '$primary4',
                },
                '&:focus': { border: '2px solid $secondary' },
            },
        },
    },

    defaultVariants: {
        variant: 'primary',
    },

    '&:disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
        backgroundColor: '$disabledButton',
        cursor: 'initial',
        border: '1px solid $disabledButton',
    },
});

export default Button;
