import React from 'react';
import { observer } from '@a2d24/emr-state/mobx-react-lite';
import { styled } from '@a2d24-ui/theme';
import { useNavigate } from 'react-router-dom';
import { getEngine } from '@a2d24/react-engine';
import { useStores } from '@a2d24/emr-state/stores';
import MFAManagementScreen from './MFAManagementScreen';
import SetupSMS from './SetupSMS';
import SetupEmail from './SetupEmail';
import useTimeout from '../../hooks/useTimeout';

const engine = getEngine();

const Logo = engine.providers('Logo', () => () => null)();

const ManageMFA = () => {
    const navigate = useNavigate();
    const [screen, setScreen] = React.useState('home');

    const { mfaStore } = useStores();
    const { mfaAuthToken } = mfaStore;

    // Give the user 5 minutes to manage their MFA devices
    const timeoutRef = useTimeout(() => {
        mfaStore.abandonChallenge();
        mfaStore.clearMfaRequired();
        navigate('/emr/preferences');
    }, 5 * 60 * 1000);

    React.useEffect(() => {
        mfaStore.setErrorMessage(null);

        if (!mfaAuthToken) {
            navigate('/emr/preferences');
        }
    }, []);

    return (
        <Container>
            <Logo />
            {screen === 'home' && <MFAManagementScreen setScreen={setScreen} />}
            {screen === 'sms-screen' && <SetupSMS setScreen={setScreen} />}
            {screen === 'email-screen' && <SetupEmail setScreen={setScreen} />}
        </Container>
    );
};

export default observer(ManageMFA);

const Container = styled('div', {
    width: '100vw',
    height: '100vh',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    placeItems: 'center',

    '> img': {
        justifySelf: 'start',
        margin: '2ch 4ch',
        maxWidth: '300px',
    },
});
