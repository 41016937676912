import { styled } from '@a2d24-ui/theme';
import Button from '../../components/Button';
import { AuthGraphic } from './AuthGraphic';
import SecureYourAccountSVG from '../../img/secure.svg';

import React from 'react';

export default ({ setScreen }) => (
    <Content>
        <div>
            <h1>Set up Two Factor Authentication</h1>
            <p>Your patients trust you to keep their information safe.</p>
            <p>
                2FA Makes your account more secure by verifying your identity when you log in from a
                new device
            </p>
            <Button
                css={{ marginTop: '2ch', fontSize: '1em', fontWeight: 400 }}
                type="button"
                variant="secondary"
                onClick={() => setScreen('home')}
            >
                Set up Two Factor Authentication
            </Button>
        </div>
        <AuthGraphic image={SecureYourAccountSVG} />
    </Content>
);

const Content = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '4ch',
    color: '$primaryBlack',
    alignItems: 'center',
    padding: '4ch',

    'div:first-of-type': {
        order: 1,
        justifySelf: 'center',
        h1: {
            margin: '1ch 0',
        },
        p: {
            margin: '2ch 0',
        },
    },

    '@bp3': {
        flexDirection: 'row',

        'div:first-of-type': {
            order: 0,
            gap: 0,
            alignSelf: 'flex-start',
            marginTop: '4ch',
            p: {
                maxWidth: '60%',
            },
        },
        'div:last-of-type': {
            flex: '1 1.125 40%',
        },
    },
});
