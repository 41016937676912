import React, { useEffect } from 'react';

const useEngineListener = (topic, callback, deps = []) => {
    useEffect(() => {
        let identifier = engine.registerListener(topic, callback);
        return () => {
            engine.unregisterListener(topic, identifier);
        };
    }, deps);
};

export default useEngineListener;
