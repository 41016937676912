import * as LabelPrimitive from '@radix-ui/react-label';

import { styled } from '@a2d24-ui/theme';

const StyledLabel = styled(LabelPrimitive.Root, {
    fontSize: '1.125em',
    fontWeight: 400,
    color: '$darkText',
    userSelect: 'none',
    marginY: '$2',
});

export default StyledLabel;
