import Fieldset from './FieldSet';
import Label from './Label';
import { Input } from './Input';

const TextField = ({
    id,
    label,
    value,
    onChange,
    placeholder,
    variant,
    state,
    type = 'text',
    labelCss = {},
    inputCss = {},
    fieldsetCss = {},
    inputProps = {},
    autoFocus = false,
}) => {
    return (
        <Fieldset variant={variant} state={state} css={fieldsetCss}>
            <Label htmlFor={id} variant={variant} state={state} css={labelCss}>
                {label}
            </Label>
            <Input
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete={'off'}
                variant={variant}
                state={state}
                css={inputCss}
                autoFocus={autoFocus}
                {...inputProps}
            />
        </Fieldset>
    );
};

export default TextField;
