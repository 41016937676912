import React from 'react';
import ReactDOM from 'react-dom';

import { keyframes, styled } from '@a2d24-ui/theme';

export const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const Overlay = styled('div', {
    backgroundColor: '$overlay',
    position: 'fixed',
    zIndex: 100,
    inset: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
});

const PassiveOverlay = React.forwardRef((props, forwardedRef) => {
    const { containerRef, style, ...portalProps } = props;
    const hostElement = containerRef?.current || document?.body;
    const [, forceUpdate] = React.useState({});

    /**
     * containerRef.current won't be set on first render, so we force a re-render.
     * Because we do this in `useLayoutEffect`, we still avoid a flash.
     */
    React.useLayoutEffect(() => {
        forceUpdate({});
    }, []);

    if (hostElement) {
        return ReactDOM.createPortal(
            <Overlay data-radix-portal="" {...portalProps} ref={forwardedRef} />,
            hostElement
        );
    }

    // bail out of ssr
    return null;
});

export default PassiveOverlay;
