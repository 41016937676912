const axios = require('axios');

export const MFA_SCOPES = 'enroll read:authenticators remove:authenticators';
export const MFA_TYPE_SMS = 'sms';
export const MFA_TYPE_EMAIL = 'email';

export const login = async (
    username,
    password,
    {
        grant_type = 'http://auth0.com/oauth/grant-type/password-realm',
        domain,
        realm,
        client_id,
        audience,
        scope = 'openid',
        extraParams = {},
    }
) =>
    axios
        .post(`https://${domain}/oauth/token`, {
            grant_type,
            scope,
            audience,
            realm,
            client_id,
            username,
            password,
            ...extraParams,
        })
        .then((res) => {
            return res.data;
        });

export const listMFA = async (token, { domain, protocol = 'https' }) =>
    axios
        .get(`${protocol}://${domain}/mfa/authenticators`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((res) => {
            return res.data;
        });

export const associateMFA = async (token, mfa_destination, { type, domain, protocol = 'https' }) =>
    axios
        .post(
            `${protocol}://${domain}/mfa/associate`,

            {
                authenticator_types: ['oob'],
                oob_channels: [type],
                [type === MFA_TYPE_SMS ? 'phone_number' : 'email']: mfa_destination,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        .then((res) => {
            return res.data;
        });

export const challengeAuthenticator = async (
    mfa_token,
    authenticator_id,
    { client_id, domain, challenge_type = 'oob', protocol = 'https' }
) =>
    axios
        .post(`${protocol}://${domain}/mfa/challenge`, {
            client_id,
            challenge_type,
            mfa_token,
            authenticator_id,
        })
        .then((res) => res.data);

export const verifyMFA = async (
    mfa_token,
    oob_code,
    binding_code,
    { domain, client_id, protocol = 'https' }
) =>
    axios
        .post(`${protocol}://${domain}/oauth/token`, {
            grant_type: 'http://auth0.com/oauth/grant-type/mfa-oob',
            client_id,
            mfa_token,
            oob_code,
            binding_code,
        })
        .then((res) => res.data);

export const removeAuthenticator = async (
    token,
    authenticator_id,
    { domain, protocol = 'https' }
) =>
    axios.post(
        `${protocol}://${domain}/mfa/remove_authenticator`,
        {
            authenticator_id,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

export const resetPassword = async (email, { domain, client_id, connection }) =>
    axios.post(`https://${domain}/dbconnections/change_password`, {
        client_id,
        connection,
        email,
    });
