import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useStores } from '@a2d24/emr-state/stores';
import { observer } from '@a2d24/emr-state/mobx-react-lite';
import EmrLayout from './EmrLayout';
import LoadingScreen from '@a2d24-ui/loading-screen';

const Logo = engine.providers('Logo', () => () => null)();

const App = observer(() => {
    const [timedOut, setTimedOut] = React.useState(false);

    const { authStore, mfaStore } = useStores();
    React.useEffect(() => {
        const timer = setTimeout(() => setTimedOut(true), 15 * 1000);
        return () => clearTimeout(timer);
    });

    const getTextToShow = () => {
        if (authStore.authorizingErrorMessage) {
            return authStore.authorizingErrorMessage;
        } else if (timedOut) {
            return 'This appears to be taking longer than usual. Please log out and try again if this persists.';
        }

        return 'Authorizing your user profile...';
    };

    if (authStore.authenticated === false) return <Navigate to="/login" />;

    if (authStore.authorized === false)
        return (
            <LoadingScreen
                loading={authStore.authorizing}
                text={getTextToShow()}
                action={authStore.authorizingErrorMessage || timedOut ? 'Log out' : null}
                onAction={() => {
                    authStore.logout();
                }}
                image={
                    <Logo
                        css={{
                            width: '150px',
                            padding: '1ch 0ch 3ch',
                            transform: 'translateX(11.7%)',
                        }}
                    />
                }
            />
        );

    return <EmrLayout />;
});

export const EmrRootRoutes = <Route path="*" element={<App />} key="emr-root-routes" />;
