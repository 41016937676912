import React from 'react';
import { styled } from '@a2d24-ui/theme';
import Button from './Button';

const TermsAndConditionsContainer = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '4ch',
    overflowY: 'auto',
    lineHeight: '1.5em',
    '> section': {
        paddingBottom: '2ch',

        '> div': {
            paddingLeft: '4ch',
        },

        h2: {
            margin: '2ch 0',
        },

        ol: {
            paddingLeft: '3ch',
        },
    },
    '> section:first-of-type': {
        h2: { margin: '0 0 2ch 0' },
    },
});

export default ({ onClick }) => (
    <TermsAndConditionsContainer>
        <section>
            <h2>ELECTRONIC MEDICAL RECORDS (EMR): TERMS OF USE</h2>
            <div>
                I (hereinafter referred to as "the User", hereby confirm that I have read,
                understood and agree to the following terms and conditions for access to the
                Electronic Medical Records (EMR) Platform (hereinafter referred to as the
                “Platform").
            </div>
        </section>
        <section>
            <h2>MODIFICATION OF THESE TERMS OF USE</h2>
            <div>
                Medicross reserves the right, at any time and without prior notice given to the
                User, to change these terms of use and the User shall be automatically bound by such
                changes.
            </div>
        </section>
        <section>
            <h2>UNLAWFUL OR PROHIBITED USE</h2>
            <div>
                The User warrants to Medicross that he/she will not:
                <br />
                <br />
                <ol>
                    <li>
                        Use the Platform for any purpose that is unlawful or prohibited by these
                        terms and conditions;
                    </li>
                    <li>
                        Use the Platform in any manner which could damage, disable, overburden, or
                        impair the Platform or interfere with any other party's use of the Platform,
                        and
                    </li>
                    <li>
                        Obtain or attempt to obtain any materials or information through any means
                        not intentionally made available or provided for through the Platform.
                    </li>
                </ol>
            </div>
        </section>
        <section>
            <h2>TERMINATION/ACCESS RESTRICTION</h2>
            <div>
                Medicross reserves the right, at its sole discretion, to terminate the User's access
                to the Platform and the related Platforms or any portion thereof at any time,
                without notice thereof.
            </div>
        </section>
        <section>
            <h2>INFORMATION AND CYBER SECURITY GUIDELINES</h2>
            <div>
                Information Technology device(s) used to access the Platform must be configured to
                securely connect to the Platform. It is the User's sole responsibility to provide
                and continuously ensure a secure connection between the User's Information
                Technology device(s) and the Platform. The User shall establish and maintain
                appropriate security measures (such as, but not limited to appropriate
                authentication measures, installation of updated antivirus programs, software
                updates etc.) to protect the Platforms against security breaches, unauthorised
                access, interference, intrusion, leakage and/or theft of data information
            </div>
        </section>
        <section>
            <h2>LIABILITY DISCLAIMER</h2>
            <div>
                <ol>
                    <li>
                        The information, software, products, and services included in or available
                        through the Platform may include inaccuracies or typographical errors.
                        Changes are periodically added to the information herein.
                    </li>
                    <li>
                        No warranties, representations, undertakings whether express or implied are
                        given in respect of this Platform (including but not limited to the content,
                        software, products, related graphics or the service provided) including the
                        reliability, accuracy, completeness, suitability and availability of this
                        Platform or of any other Web Platform or Linked Platform which may be
                        accessed through this Platform. Accordingly, Medicross shall not be bound in
                        any manner by any information contained in the abovementioned. Any reliance
                        on any information on this Platform shall be at the User's own risk.
                    </li>
                    <li>
                        To the maximum extent permitted by the applicable law, in no event shall
                        Medicross, its affiliates and/or its agents be liable for any direct,
                        indirect, punitive, incidental, special, consequential damages or any
                        damages whatsoever including, without limitation, damages for loss of use,
                        data or profits, arising out of or in any way connected with the use or
                        performance of the Platform, with the delay or inability to use the Platform
                        or related services, the provision of or failure to provide the Platform, or
                        for any information, software, products, services and related graphics
                        obtained through the Platform, or otherwise arising out of the use of the
                        Platform.
                    </li>
                    <li>
                        Whilst Medicross shall endeavour to ensure the security of the Platform,
                        Medicross provides no warranty or guarantee that the Platform will be error
                        and virus free and will therefore accept no liability for damage or loss
                        that the User may suffer as a result of any electronic communications.
                    </li>
                    <li>
                        If the User is dissatisfied with any portion of the Platform, or with any of
                        these terms of use, the User's sole and exclusive remedy is to discontinue
                        using the Platform.
                    </li>
                </ol>
            </div>
        </section>
        <section>
            <h2>GENERAL</h2>
            <div>
                <ol>
                    <li>
                        The content and design of pages of this Platform are subject to copyright.
                        Ownership of all intellectual property rights to this Platform vests in
                        Medicross, unless otherwise specified.
                    </li>
                    <li>
                        The User agrees that he/she and/or any person acting on his/her behalf, will
                        preserve the confidentiality of any patient information and that this
                        obligation shall continue indefinitely. A breach of this requirement will
                        constitute a material breach of these Terms of Use and Medicross shall be
                        entitled to terminate the User’s access to the Platform with immediate
                        effect.
                    </li>
                    <li>
                        The User agrees that the use of this Platform and any dispute arising out of
                        the use of this Platform is subject to the laws of the Republic South
                        Africa.
                    </li>
                </ol>
            </div>
        </section>
        <Button css={{ minHeight: '2.5em' }} onClick={onClick}>
            Close
        </Button>
    </TermsAndConditionsContainer>
);
