export const HoverStyles = {
    '::-moz-focus-inner': {
        border: 'none',
    },

    '&:hover:enabled': {
        transition: '150ms',
        filter: 'brightness(115%)',
    },

    '&:focus:enabled': {
        transition: '150ms',
        filter: 'brightness(115%)',
    },

    '&:disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
        backgroundColor: '$disabledButton',
        cursor: 'initial',
        border: '1px solid $disabledButton',
    },
};
