import React from 'react';
import { keyframes, styled } from '@a2d24-ui/theme';
import { Button } from '@a2d24-ui/button';

const Main = styled('div', {
    padding: 0,
    margin: 0,
    height: '100%',
    width: '100%',
    background: '$primary4',
    color: '$primaryTextDark',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto',
    alignItems: 'center',
    alignContent: 'center',
    justifyItems: 'center',
});

const Text = styled('p', {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '1.3em',
    userSelect: 'none',
});

const rotate = keyframes({
    '0%': {
        '-webkit-transform': 'rotate(0deg) scale(1)',
        transform: 'rotate(0deg) scale(1)',
    },
    '50%': {
        '-webkit-transform': 'rotate(180deg) scale(0.6)',
        transform: 'rotate(180deg) scale(0.6)',
    },
    '100%': {
        '-webkit-transform': 'rotate(360deg) scale(1)',
        transform: 'rotate(360deg) scale(1)',
    },
});

const Square = styled('div', {
    width: 100,
    height: 100,
    display: 'grid',
    alignItems: 'center',
    gridTemplateAreas: '"content"',
    boxSizing: 'border-box',

    variants: {
        variant: {
            loading: {
                div: {
                    '-webkit-animation-fill-mode': 'both',
                    animationFillMode: 'both',
                    border: '1px solid $primaryTextDark',
                    borderBottomColor: 'transparent',
                    borderTopColor: 'transparent',
                    borderRadius: '100%',
                    width: 35,
                    height: 35,
                    gridArea: 'content',
                    '-webkit-animation': `${rotate} 1s 0s ease-in-out infinite`,
                    animation: `${rotate} 1s 0s ease-in-out infinite`,
                    margin: '0 auto',
                },

                'div:last-child': {
                    width: 15,
                    height: 15,
                    '-webkit-animation-duration': '0.5s',
                    'animation-duration': '0.5s',
                    'border-color': '$primaryTextDark transparent $primaryTextDark transparent',
                    '-webkit-animation-direction': 'reverse',
                    animationDirection: 'reverse',
                },
            },
        },
    },
});

const LoadingScreen = ({ loading = true, image, text, action, onAction = () => {} }) => {
    return (
        <Main>
            <Square variant={loading ? 'loading' : null}>
                <div />
                <div />
            </Square>
            {image}

            <Text>{text}</Text>

            <Button
                onClick={(e) => {
                    e.preventDefault();
                    onAction(action);
                }}
                css={{
                    marginTop: '2ch',
                    visibility: action ? 'inherit' : 'hidden',
                }}
            >
                {action}
            </Button>
        </Main>
    );
};

export default LoadingScreen;
