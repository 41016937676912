import ModuleManager from './ModuleManager';

const initEngine = (engineConfig) => {
    const moduleGenerators = engineConfig.moduleGenerators;
    const moduleManager = new ModuleManager({ moduleGenerators });

    global.engine = {
        moduleManager,
        getComponentFromModule: (moduleId, componentId) =>
            moduleManager.component(`${moduleId}.${componentId}`),
        getMethodFromModule: (moduleId, methodId) =>
            moduleManager.method(`${moduleId}.${methodId}`),
        registerLifecycleEvent: (lifecycleMethod, callback) =>
            moduleManager.registerLifecycleEvent(lifecycleMethod, callback),
        unregisterLifecycleEvent: (lifecycleMethod, callbackId) =>
            moduleManager.unregisterLifecycleEvent(lifecycleMethod, callbackId),
        registerListener: (topic, callback) => moduleManager.registerListener(topic, callback),
        unregisterListener: (topic, callbackId) =>
            moduleManager.unregisterListener(topic, callbackId),
        getRoutes: () => moduleManager.getRoutes(),
        broadcastMessage: (topic, notification) =>
            moduleManager.broadcastMessage(topic, notification),
        triggerLifecycleEvent: (lifeCycleName, args) =>
            moduleManager.triggerLifecycleEvent(lifeCycleName, args),
        providers: (type, defaultValue) => moduleManager.providers(type, defaultValue),
        component: (id) => moduleManager.component(id),
        require: (moduleName) => moduleManager.require(moduleName),
        invoke: (...args) => moduleManager.invoke(...args),
    };

    moduleManager.init();
    engine.rootComponent = moduleManager.component(engineConfig.render);
};

export const getEngine = () => global.engine;
export default initEngine;

export const EngineRoute = ({
    path,
    component,
    menuName,
    roles = null,
    icon = null,
    authenticatedRoute = false,
    authenticationRoute = false,
    fullscreen = false,
    exact = false,
    index = null,
}) => {
    return {
        path,
        component,
        menuName,
        roles,
        icon,
        authenticatedRoute,
        authenticationRoute,
        fullscreen,
        exact,
        index,
    };
};
//
// function invokeSafely(callback, args) {
//     try {
//         callback(...args);
//     } catch (err) {
//         EngineError.error(err);
//     }
// }
