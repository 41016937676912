import React from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';

const withWrappers = engine.providers('withWrappers')();

const rootRoutes = engine.providers('root-routes').map((generator) => generator());

const RootRouter = () => {
    return (
        <BrowserRouter>
            <Routes>{rootRoutes}</Routes>
        </BrowserRouter>
    );
};

export default withWrappers(RootRouter);
