import React from 'react';

import { Navigate, useNavigate } from 'react-router-dom';
import { observer } from '@a2d24/emr-state/mobx-react-lite';

import { useStores } from '@a2d24/emr-state/stores';

import TextField from '@a2d24-ui/textfield';
import Button from '../components/Button';

import { LoginContainer, StyledLogo, LoginForm, AUTH0_REALM } from './Login';
import useTimeout from '../hooks/useTimeout';

const LoginForMFAToken = () => {
    const { mfaStore } = useStores();
    const navigate = useNavigate();

    const {
        username,
        password,
        requestInFlight,
        errorMessage,
        realm,
        mfaPending,
        mfaAuthenticatorsFetched,
        mfaAuthToken,
    } = mfaStore;
    const [loginClicked, setLoginClicked] = React.useState(false);

    React.useEffect(() => {
        mfaStore.setErrorMessage(null);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoginClicked(true);
        mfaStore.getMFAToken();
    };

    const timeoutRef = useTimeout(() => {
        mfaStore.abandonChallenge();
        mfaStore.clearMfaRequired();
        navigate('/emr/preferences');
    }, 5 * 60 * 1000);

    if (mfaPending && mfaAuthenticatorsFetched) {
        return <Navigate to="../mfa?next=/login/mfa/manage" />;
    }

    if (mfaAuthToken) {
        return <Navigate to="../mfa/manage" />;
    }

    return (
        <LoginContainer css={{ '.info-text': { padding: '2ch', color: '$primary5' } }}>
            <StyledLogo />
            <p className="logo-text">Electronic Medical Records</p>

            <p className="info-text">We're asking you to log in again to verify it is you</p>

            <LoginForm onSubmit={handleSubmit}>
                <TextField
                    id="username"
                    label={realm === AUTH0_REALM ? 'Netcare Digital Username' : 'Username'}
                    value={username || ''}
                    onChange={(e) => mfaStore.setUsername(e.target.value)}
                    variant="large"
                    inputCss={{
                        '&:focus': {
                            background: '$primary4',
                        },
                    }}
                    state={loginClicked && !username ? 'error' : null}
                    inputProps={{
                        disabled: true,
                    }}
                />
                <TextField
                    id="password"
                    label="Password"
                    type="password"
                    value={password || ''}
                    onChange={(e) => mfaStore.setPassword(e.target.value)}
                    variant="large"
                    inputCss={{
                        '&:focus': {
                            background: '$primary4',
                        },
                    }}
                    autoFocus
                    state={loginClicked && !password ? 'error' : null}
                />
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <Button
                    css={{ marginTop: '1ch' }}
                    type="submit"
                    disabled={requestInFlight}
                    onClick={handleSubmit}
                >
                    Verify
                </Button>
            </LoginForm>
        </LoginContainer>
    );
};

export default observer(LoginForMFAToken);
