import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const ExclamationIcon = styled(
    React.forwardRef(
        ({ color = '#202020', backgroundColor = '#E4AF42', ...props }, forwardedRef) => (
            <svg width="64" height="56" {...props} version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <path
                        d="M29.646 1.308.354 52.042c-1.007 1.744.252 3.924 2.266 3.924h58.583c2.013 0 3.272-2.18 2.265-3.924L34.177 1.308c-1.007-1.744-3.524-1.744-4.531 0"
                        fill={backgroundColor}
                    />
                    <path
                        d="M30.874 5.378 4.494 51.071a1.198 1.198 0 0 0 1.037 1.796h52.76c.923 0 1.499-.998 1.038-1.796L32.949 5.378a1.198 1.198 0 0 0-2.075 0z"
                        stroke="#000"
                    />
                    <text
                        fontFamily="Roboto-Medium, Roboto"
                        fontSize="36"
                        fontWeight="400"
                        fill={color}
                    >
                        <tspan x="27.175" y="47">
                            !
                        </tspan>
                    </text>
                </g>
            </svg>
        )
    ),
    {}
);
