import React, { useState } from 'react';
import { keyframes, styled } from '@a2d24-ui/theme';
import { mauve } from '@radix-ui/colors';
import { Cross2Icon } from '@radix-ui/react-icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import Button from '../components/Button';
import TextField from '@a2d24-ui/textfield';
import PassiveOverlay from './PassiveOverlay';
import { useStores } from '@a2d24/emr-state/stores';

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

function Root({ children, ...props }) {
    return <DialogPrimitive.Root {...props}>{children}</DialogPrimitive.Root>;
}

const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 25,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$lightText',
    cursor: 'pointer',

    '&:hover': { backgroundColor: '$primary4' },
    '&:focus': { boxShadow: `0 0 0 2px $colors$primary3` },
});

const StyledContent = styled(DialogPrimitive.Content, {
    display: 'grid',
    gridTemplateAreas: '"sidebar main"',
    gridTemplateColumns: 'auto 1fr',
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow:
        'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',

    '@bp3': {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        maxWidth: '50vw',
        height: 'auto',
        maxHeight: '50vh',
    },

    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
        willChange: 'transform',
    },
    '&:focus': { outline: 'none' },
});

const StyledTitle = styled(DialogPrimitive.Title, {
    margin: 0,
    fontWeight: 500,
    color: mauve.mauve12,
    fontSize: 17,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
});

const StyledDescription = styled(DialogPrimitive.Description, {
    margin: '10px 0 20px',
    color: mauve.mauve11,
    fontSize: 15,
    lineHeight: 1.5,
    maxWidth: '25vw',
});

// Aliases
const Dialog = Root;
const DialogContent = StyledContent;
const DialogTitle = StyledTitle;
const DialogDescription = StyledDescription;
const DialogClose = DialogPrimitive.Close;

const Main = styled('div', {
    all: 'unset',
    fontFamily: 'inherit',
    position: 'relative',
    padding: 25,
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: 'auto minmax(0, 1fr)',
    gridArea: 'main',
});

const StyledCross2Icon = styled(Cross2Icon, {
    width: 25,
    height: 25,
});

const Form = styled('form', {
    display: 'flex',
    flexDirection: 'column',
});

export const useControlledState = (controlledOpen = null, controlledSetOpen = null) => {
    const [open, setOpen] = React.useState(false);

    if (controlledOpen !== null) return [controlledOpen, controlledSetOpen];

    return [open, setOpen];
};

const RenderStatus = (linkStatus = null) => {
    return (
        linkStatus?.status && (
            <LinkStatusMessage variant={linkStatus?.status === 200 ? 'success' : 'fail'}>
                {linkStatus?.message}
            </LinkStatusMessage>
        )
    );
};

const realmError =
    'You have selected to log in using your Netcare/Medicross credentials, please log a Sigma if you require assistance with accessing your account.';
const realmSuccess = `Enter your email address and we'll send you a link to reset your password.`;

const ForgotPasswordWidget = ({
    // Flags
    showOverlay = true,
    closeOnOutsideInteraction = false,

    // Controlled inputs
    open: controlledOpen = null,
    setOpen: controlledSetOpen = null,

    dialogProps = {},
    containerProps = {},
}) => {
    const { mfaStore } = useStores();
    const [isOpen, setOpen] = useControlledState(controlledOpen, controlledSetOpen);
    const [requestInFlight, setRequestInFlight] = useState(false);

    const { username } = mfaStore;

    const [linkStatus, setLinkSent] = useState({
        message: '',
        status: null,
    });

    const isNetcareDigitalUser = mfaStore.realm === 'Username-Password-Authentication';

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setRequestInFlight(true);
        try {
            const result = await mfaStore.resetPasswordPromise();
            setLinkSent({
                message: result.data || 'Unable to reset password',
                status: result.status || 500,
            });
        } catch (e) {
            setLinkSent({
                message: e?.response?.data?.error || 'Unable to reset password',
                status: e?.result?.status || 500,
            });
        }
        setRequestInFlight(false);
    };

    return (
        <>
            {isOpen && showOverlay && <PassiveOverlay />}
            <Dialog open={isOpen} onOpenChange={setOpen} modal={false} {...dialogProps}>
                <DialogContent
                    onInteractOutside={(e) => !closeOnOutsideInteraction && e.preventDefault()}
                >
                    <Main {...containerProps}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <DialogTitle>
                                {isNetcareDigitalUser ? 'Reset password' : 'Trouble signing in?'}
                            </DialogTitle>
                            <DialogDescription>
                                {isNetcareDigitalUser ? realmSuccess : realmError}
                            </DialogDescription>
                        </div>
                        {isNetcareDigitalUser && !linkStatus.status && (
                            <Form onSubmit={handleSubmit}>
                                <TextField
                                    id={'email'}
                                    type="email"
                                    // label="Email Address"
                                    placeholder="e.g. email@domain.com"
                                    autoFocus={true}
                                    value={username || ''}
                                    inputCss={{ height: '40px' }}
                                    fieldsetCss={{
                                        margin: '10px 0',
                                        span: {
                                            display: 'none',
                                        },
                                    }}
                                    onChange={(e) => {
                                        mfaStore.setUsername(e.target.value);
                                    }}
                                    inputProps={{
                                        type: 'email',
                                        required: 'required',
                                    }}
                                />
                                <Button
                                    variant="secondary"
                                    type="submit"
                                    disabled={requestInFlight}
                                    css={{ margin: '20px 0 0' }}
                                >
                                    Send link
                                </Button>
                            </Form>
                        )}
                        {RenderStatus(linkStatus)}
                    </Main>

                    <DialogClose asChild>
                        <IconButton
                            css={{
                                width: 35,
                                height: 35,
                                position: 'absolute',
                                top: 10,
                                right: 10,
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpen(false);
                            }}
                        >
                            <StyledCross2Icon />
                        </IconButton>
                    </DialogClose>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ForgotPasswordWidget;

const LinkStatusMessage = styled('div', {
    textAlign: 'center',
    color: '$primary',

    variants: {
        variant: {
            success: {
                color: '$primary5',
            },
            fail: {
                color: '$warning',
            },
        },
    },
});
