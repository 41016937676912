import React from 'react';

const useTimeout = (callback, delay) => {
    const timeoutRef = React.useRef(null);
    const savedCallback = React.useRef();

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
        if (!delay || typeof delay !== 'number') return;

        timeoutRef.current = setTimeout(() => {
            savedCallback.current();
        }, delay);

        return () => clearTimeout(timeoutRef.current);
    }, [delay]);

    return timeoutRef;
};

export default useTimeout;
