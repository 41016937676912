export default class RootRouter {
    components() {
        return [
            {
                id: 'root-router.RootRouter',
                generator: () => require('./RootRouter').default,
                componentType: ['RootRouter'],
            },
        ];
    }
}
