import React from 'react';
import { AlertDialog } from '@a2d24-ui/alert-dialog';

const AlertDialogComponent = ({ title, message, open, setOpen }) => (
    <AlertDialog
        open={open}
        setOpen={setOpen}
        title={title}
        content={message}
        hideAction={true}
        cancelButtonText={'OK'}
        cancelIsPrimary={true}
    />
);

export default AlertDialogComponent;
