import React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { Flex, styled } from '@a2d24-ui/theme';
import { CheckIcon, DividerHorizontalIcon } from '@radix-ui/react-icons';

export const StyledCheckbox = styled(CheckboxPrimitive.Root, {
    all: 'unset',
    boxSizing: 'border-box',
    borderRadius: '$1',
    border: '1px solid $inputBorder',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        backgroundColor: '$selectHoverColor',
        boxShadow: '0 0 0 0px $primary, 0 0 0px 6px $colors$primary3',
    },
    '&:focus': {
        boxShadow: '0 0 0 2px $primary, 0 0 3px 6px $colors$primary3',
    },
    boxShadow: '0 0 0 0px $primary, 0 0 0 0 $colors$primary3',
    transition: 'box-shadow 0.5s',
    variants: {
        size: {
            medium: {
                square: '$checkboxSizeCompact',
                minSquare: '$checkboxSizeCompact',
            },
            large: {
                square: '$checkboxSize',
                minSquare: '$checkboxSize',
            },
        },
        type: {
            filled: {
                '&[data-state="checked"], &[data-state="indeterminate"]': {
                    backgroundColor: '$primary',
                },
            },
            normal: {
                backgroundColor: '$backgroundCard',
            },
        },
    },
    defaultVariants: {
        size: 'medium',
        type: 'filled',
    },
});

export const CheckboxIndicator = styled(CheckboxPrimitive.Indicator, {
    variants: {
        type: {
            filled: {
                '&[data-state="checked"], &[data-state="indeterminate"]': {
                    color: 'white',
                },
            },
            normal: {
                color: '$primary',
            },
        },
    },

    defaultVariants: {
        type: 'filled',
    },
});

const iconStyles = {
    paddingTop: 2,

    variants: {
        size: {
            medium: {
                square: 15,
            },
            large: {
                square: 22,
            },
        },
    },
    defaultVariants: {
        size: 'medium',
    },
};

export const StyledCheckIcon = styled(CheckIcon, iconStyles);
export const StyledDividerHorizontalIcon = styled(DividerHorizontalIcon, iconStyles);

export const Label = styled('label', {
    color: '$primaryText',
    fontSize: '$5',
    lineHeight: 1,
    userSelect: 'none',
    paddingRight: '2ch',
});

export const CheckboxV2 = ({
    id,
    text,
    checked,
    onCheckedChange,
    disabled,
    css,
    type,
    size,
    returnResultIfFalse = true,
}) => {
    const variantProps = { size, type };

    return (
        <Flex css={{ alignItems: 'center', padding: '0.75ch 0', ...css }}>
            <StyledCheckbox
                checked={checked}
                onCheckedChange={(v) =>
                    !v && returnResultIfFalse
                        ? onCheckedChange(v)
                        : !v
                        ? onCheckedChange(undefined)
                        : onCheckedChange(v)
                }
                disabled={disabled}
                id={id}
                {...variantProps}
            >
                <CheckboxIndicator {...variantProps}>
                    {checked === 'indeterminate' && (
                        <StyledDividerHorizontalIcon {...variantProps} />
                    )}
                    {checked === true && <StyledCheckIcon {...variantProps} />}
                    {checked === undefined && <StyledCheckIcon {...variantProps} />}
                </CheckboxIndicator>
            </StyledCheckbox>
            <Label css={{ paddingLeft: '1ch' }} htmlFor={id} {...variantProps}>
                {text}
            </Label>
        </Flex>
    );
};
