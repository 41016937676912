import React from 'react';
import { keyframes, styled } from '@a2d24-ui/theme';
import { Cross2Icon } from '@radix-ui/react-icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
    backgroundColor: '$overlay',
    position: 'fixed',
    inset: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
});

function Root({ children, ...props }) {
    return (
        <DialogPrimitive.Root {...props}>
            <StyledOverlay />
            {children}
        </DialogPrimitive.Root>
    );
}

const StyledTitle = styled(DialogPrimitive.Title, {
    margin: 0,
    fontWeight: 500,
    color: '$primary5',
    fontSize: 17,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
});

const StyledDescription = styled(DialogPrimitive.Description, {
    margin: '10px 0 20px',
    color: '$primary5',
    fontSize: 15,
    lineHeight: 1.5,
});

const StyledContent = styled(DialogPrimitive.Content, {
    display: 'grid',
    gridTemplateColumns: '1fr',
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow:
        'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',

    '@bp3': {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85vw',
        maxWidth: '85vw',
        height: '85vh',
        maxHeight: '85vh',
    },

    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
        willChange: 'transform',
    },
    '&:focus': { outline: 'none' },

    '> div:first-of-type': {
        display: 'flex',
        flexDirection: 'column',
        padding: '2ch',
        borderBottom: '1px solid $separatorColor',
        textAlign: 'center',
    },
});

const Dialog = Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogContent = StyledContent;
const DialogTitle = StyledTitle;
const DialogDescription = StyledDescription;
const DialogClose = DialogPrimitive.Close;

const IconButton = styled('button', {
    all: 'unset',
    fontFamily: 'inherit',
    borderRadius: '100%',
    height: 25,
    width: 25,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$lightText',
    cursor: 'pointer',

    '&:hover': { backgroundColor: '$primary4' },
    '&:focus': { boxShadow: `0 0 0 2px $colors$primary3` },
});

const StyledCross2Icon = styled(Cross2Icon, {
    width: 25,
    height: 25,
});

const BaseDialog = ({
    renderTrigger: Trigger,
    title,
    description = null,
    renderContent: Content,
    isOpen,
    setOpen,
}) => (
    <Dialog open={isOpen} onOpenChange={setOpen}>
        {Trigger && (
            <DialogTrigger asChild>
                <Trigger />
            </DialogTrigger>
        )}
        <StyledContent>
            <div>
                {title && <DialogTitle>{title}</DialogTitle>}
                {description && <DialogDescription>{description}</DialogDescription>}
            </div>
            <Content />
            <DialogClose asChild>
                <IconButton
                    css={{
                        width: 35,
                        height: 35,
                        position: 'absolute',
                        top: 10,
                        right: 10,
                    }}
                >
                    <StyledCross2Icon />
                </IconButton>
            </DialogClose>
        </StyledContent>
    </Dialog>
);

export default BaseDialog;
