import React from 'react';
import { getEngine } from '@a2d24/react-engine';

export function compose(...funcs) {
    if (funcs.length === 0) {
        return (arg) => arg;
    }

    if (funcs.length === 1) {
        return funcs[0];
    }

    return funcs.reduce(
        (a, b) =>
            (...args) =>
                a(b(...args))
    );
}

const engine = getEngine();

const wrappers = engine.providers('root-wrappers').map((generator) => generator());

const withWrappers = compose(...wrappers);

export default withWrappers;
