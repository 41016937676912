import React, { useState } from 'react';
import useEngineListener from '@a2d24/react-engine-dom/hooks/useEngineListener';
import toast, { Toaster } from 'react-hot-toast';
import AlertDialog from './AlertDialog';
import { theme } from '@a2d24-ui/theme';

const withAlertProvider = (Wrapped) => (props) => {
    const [message, setMessage] = useState(null);
    const [open, setOpen] = useState(false);
    const onSnackbarAlert = (notification) => {
        if (notification.type === 'error') {
            setMessage(notification.message);
            setOpen(true);
        } else if (notification.type === 'success') toast.success(notification.message);
        else if (notification.type === 'toastError') toast.error(notification.message);
    };

    useEngineListener('snackbar', onSnackbarAlert);

    return (
        <>
            <Toaster
                position="bottom-left"
                reverseOrder={false}
                toastOptions={{
                    success: {
                        style: {
                            color: '#fff',
                            background: theme.colors.success,
                        },
                    },
                    error: {
                        style: {
                            color: '#fff',
                            background: theme.colors.warning,
                        },
                    },
                }}
            />
            <AlertDialog title={'Error'} message={message} open={open} setOpen={setOpen} />
            <Wrapped {...props} />
        </>
    );
};

export default withAlertProvider;
