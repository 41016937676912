import React from 'react';
import { styled } from '@a2d24-ui/theme';

import Button from '../../components/Button';

export default ({ setScreen }) => (
    <Content>
        <h1>How would you like to secure your account?</h1>
        <div>
            <h2>Email</h2>
            <p>You'll receive a 6-digit verification code via email upon login</p>
            <Button type="button" variant="outlined" onClick={() => setScreen('email-screen')}>
                Set up Email
            </Button>
        </div>
        <div>
            <h2>SMS Text Message</h2>
            <p>You'll receive a 6-digit verification code via SMS Text Message upon login</p>
            <Button type="button" variant="outlined" onClick={() => setScreen('sms-screen')}>
                Set up SMS
            </Button>
        </div>
    </Content>
);

const Content = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '4ch',
    color: '$primaryBlack',
    alignItems: 'center',
    padding: '4ch',
    maxWidth: '80ch',
    width: '90vw',
    alignSelf: 'flex-start',

    h1: {
        margin: '1ch 0',
        fontWeight: 300,
        fontSize: '1.5em',
    },
    '> div': {
        width: '100%',
        padding: '3ch',
        border: '1px solid $cardIconSeparatorColor',
        borderRadius: '$2',

        display: 'grid',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1fr auto',
        gap: '2ch',
        h2: {
            fontSize: '1.4em',
            fontWeight: 300,
            gridColumn: '1 / span 2',
            gridRow: 1,
        },
        p: {
            gridRow: 2,
            gridColumn: 1,
        },
        button: {
            gridRow: '1 / span 2',
            gridColumn: 2,
            placeSelf: 'center',
            minWidth: '15ch',
            fontSize: '1em',
            fontWeight: 400,
        },
    },
});
