import React from 'react';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
        },
    },
    queryCache: new QueryCache({
        onError: (error) => {
            if (error?.response?.status === 403) {
                engine.broadcastMessage('snackbar', {
                    type: 'toastError',
                    message: error.response.data,
                });
            }
        },
    }),
});

const withQueryClientProvider = (Wrapped) => (props) =>
    (
        <QueryClientProvider client={queryClient} contextSharing={true}>
            <ReactQueryDevtools position={'bottom-right'} initialIsOpen={false} />
            <Wrapped {...props} />
        </QueryClientProvider>
    );

export default withQueryClientProvider;
