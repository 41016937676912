import set from 'lodash.set';

const _ = { set };

export class EngineError extends Error {
    static error(...args) {
        console.error('EngineError:', ...args);
    }
}

class ModuleManager {
    constructor({ moduleGenerators }) {
        this.moduleGenerators = moduleGenerators;
    }

    init() {
        this.modules = {};
        this.routesRegister = {};
        this.registeredComponents = {};
        this.registeredFunctions = {};
        this.componentTypeRegistry = {};
        this.eventListeners = {};
        this.lifecycleCallbackRegister = {};
        this._providers = {};
        this._createModules(this.moduleGenerators);
    }

    _get_uuid() {
        return Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    registerLifecycleEvent(lifeCycleName, callback) {
        if (!lifeCycleName.startsWith('on')) {
            EngineError.error(
                `Calling a lifecycle function that does not begin with on ${lifeCycleName} `
            );
            return;
        }

        let callbackId = this._get_uuid();
        _.set(this.lifecycleCallbackRegister, [lifeCycleName, callbackId], callback);
        return callbackId;
    }

    unregisterLifecycleEvent(lifecycleMethod, callbackId) {
        if (this.lifecycleCallbackRegister[lifecycleMethod]?.[callbackId])
            delete this.lifecycleCallbackRegister[lifecycleMethod][callbackId];
    }

    _registerModules() {
        this._forEachModule(({ module }) => {
            module.components().forEach(({ id, generator }) => {
                this.registeredComponents[id] = generator;
            });
        });
    }

    triggerLifecycleEvent(lifeCycleName, args = {}) {
        if (!lifeCycleName.startsWith('on')) {
            EngineError.error(
                `Calling a lifecycle function that does not begin with on: ${lifeCycleName} `
            );
            return;
        }
        this._forEachModule(({ module, moduleName }) => {
            if (!module[lifeCycleName]) {
                return;
            }
            try {
                module[lifeCycleName]();
            } catch (e) {
                EngineError.error(`Module ${moduleName} threw error in ${lifeCycleName}`);
                throw e; // Preserve original context
            }
        });
        if (this.lifecycleCallbackRegister[lifeCycleName]) {
            Object.values(this.lifecycleCallbackRegister[lifeCycleName]).forEach((cb) => {
                cb(args);
            });
        }
    }

    _registerModuleComponents(moduleName) {
        this.modules[moduleName].components().forEach(({ id, generator, componentType }) => {
            this.registeredComponents[id] = generator;

            componentType.forEach((item) => {
                if (this.componentTypeRegistry[item]) {
                    this.componentTypeRegistry[item] = [...this.componentTypeRegistry[item], id];
                } else {
                    this.componentTypeRegistry[item] = [id];
                }
            });
        });
    }

    _registerModuleMethods(moduleName) {
        this.modules[moduleName].methods().forEach(({ id, generator }) => {
            this.registeredFunctions[id] = generator;
        });
    }

    _registerModuleRoutes(moduleName) {
        try {
            this.modules[moduleName].routes().forEach((route) => {
                _.set(this.routesRegister, [route.path], route);
            });
        } catch (e) {
            EngineError.error(`Unable to register routes for module: ${moduleName}`);
            throw e;
        }
    }

    _registerModuleListeners(moduleName) {
        this.modules[moduleName].listeners().forEach(({ topic, callback }) => {
            this.addListener(topic, callback, moduleName);
        });
    }

    _registerProviders(moduleName) {
        const provides = this.modules[moduleName].provides();

        Object.entries(provides).forEach(([type, provided]) => {
            if (provided instanceof Array) {
                if (!this._providers[type]) this._providers[type] = [];

                this._providers[type].push(...provided);
            } else {
                this._providers[type] = provided;
            }
        });
    }

    _registerModule(moduleName) {
        const module = this.modules[moduleName];
        module.provides && this._registerProviders(moduleName);
        module.methods && this._registerModuleMethods(moduleName);
        module.components && this._registerModuleComponents(moduleName);
        module.routes && this._registerModuleRoutes(moduleName);
        module.listeners && this._registerModuleListeners(moduleName);
        this.triggerLifecycleEvent('onInit');
    }

    _createModules(moduleGenerators) {
        Object.entries(moduleGenerators).forEach(([moduleName, moduleGenerator]) => {
            try {
                if (!this.modules[moduleName] && moduleGenerator) {
                    this.modules[moduleName] = new (moduleGenerator())();
                    this._registerModule(moduleName);
                }
            } catch (e) {
                EngineError.error('Error constructing module ' + moduleName);
                throw e; // Preserve original stack trace
            }
        });
        this.triggerLifecycleEvent('onApplicationLoaded');
    }

    _forEachModule(fn, shouldBreak = () => false) {
        Object.entries(this.modules).forEach(([moduleName, module]) => {
            fn({ moduleName, module });
            if (shouldBreak && shouldBreak()) {
                return false;
            }
        });
    }

    component(globalID) {
        const generator = this.registeredComponents[globalID];
        if (!generator) {
            EngineError.error(`ModuleRegistry.component ${globalID} used but not yet registered`);
            return undefined;
        }
        return generator();
    }

    method(globalID) {
        return this.registeredFunctions[globalID]();
    }

    providers(type, defaultValue = []) {
        return this._providers[type] || defaultValue;
    }

    getRoutes() {
        return Object.values(this.routesRegister);
    }

    invoke(globalID, ...args) {
        const generator = this.registeredFunctions[globalID];
        if (!generator) {
            EngineError.error(`ModuleRegistry.function ${globalID} used but not yet registered`);
            return undefined;
        }
        const method = generator();
        return method(...args);
    }

    require(module) {
        // Look up a module in the registry. If it exposes an exports generator, call it, else return an empty export
        if (!this.modules[module]) {
            throw new EngineError(
                `The requested module ${module} was not registered. Please ensure it is part of engine.json`
            );
        }

        const mod = this.modules[module];

        if (!mod.exports) {
            return {};
        }

        const exportsGenerator = mod.exports();
        return exportsGenerator();
    }

    registerListener(topic, callback) {
        let callbackId = this._get_uuid();
        this.addListener(topic, callback, callbackId);
        return callbackId;
    }

    unregisterListener(topic, callbackId) {
        if (this.eventListeners[topic]?.[callbackId]) delete this.eventListeners[topic][callbackId];
    }

    broadcastMessage(topic, notification) {
        if (!this.eventListeners[topic]) {
            console.warn(`${topic} does not have any subscriptions`);
            return;
        }
        Object.keys(this.eventListeners[topic]).forEach((item) => {
            try {
                this.eventListeners[topic][item](notification);
            } catch (e) {
                EngineError.error(`Error occurred in module: ${item} listener`);
                throw e;
            }
        });
    }

    addListener(topic, callback, moduleName) {
        _.set(this.eventListeners, [topic, moduleName], callback);
    }

    getComponentIdsByType(componentType) {
        return this.componentTypeRegistry[componentType];
    }
}

export default ModuleManager;
